html {
    font-family: 'Saira', sans-serif !important;
}


/* Styling for headings */
h1{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: -.03em;
    font-weight: 550;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    color: #111;
}

h2 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: -.01em;
    font-size: 1.414rem;
    font-weight: 550;
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    color: #111;
}

h3 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: -.02em;
    font-weight: 550;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    color: #111;
}

/* Styling for reducing font weight */
thin {
    font-weight: 400;
}

/* Styling for making a sub-heading */
sub {
    color: #858484;
    font-size: 0.92em;
    font-weight: 100;
}

subsub {
    color: #9b9b9b;
    font-size: 0.8rem;
    font-weight: 100;
}

/* Styling for paragraphs */
p {
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #111;
}

/* Styling for hyperlinks */
a {
    font-weight: 400;
    text-decoration: none;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid rgba(140, 191, 221, 0.582);
    color: rgba(10, 106, 161, 0.767);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

    a:hover {
        border-bottom: 2px solid rgba(101, 137, 158, 0.582);
        color: rgba(9, 73, 110, 0.767);
    }

    a:visited {
        border-bottom: 2px solid rgba(126, 101, 158, 0.582);
        color: rgba(61, 9, 110, 0.767);
    }

/* Styling for buttons */
button {
    color: #9b9b9b;
    text-decoration: none;
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
    button:hover {
        color: #111;
    }


/* Styling for blockquotes */
bq {
    margin: 0;
    font-size: 1.2rem;
    color: #636161;
    border-left: 4px solid #292929;
    padding-left: 10px;
    font-family: medium-content-title-font, Georgia, Cambria, "Times New Roman", Times, serif;
    line-height: 1.5rem;
    font-style: italic;
}

/* Styling for code components */
code {
    font-size: 85%;
    font-family: "SFMono-Regular", Consolas, Menlo, monospace;
    padding: .2em .4em;
    margin: 0;
    background-color: rgba(15, 116, 199, 0.144);
    border-radius: 3px;
    box-sizing: inherit;
    color: #111;
}

/* Styling for pre components */
pre {
    line-height: 1.45;
    margin: 0rem;
    padding: 16px;
    background-color: #eaf0f7bb;
    border-radius: 3px;
    font-size: 85%;
    font-family: "SFMono-Regular", Consolas, Menlo, monospace;
    color: #111;
}

textinput {
    background-color: rgba(240, 240, 240, 0.219);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    width: 30%;
    padding: 8px 20px;
    padding-left: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid rgba(17, 17, 17, 0.418);
    border-radius: 3px;
}

submit {
    background-color: rgba(196, 196, 196, 0.418);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    padding: 0.5em;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 5px;
}

/* Styling for inline blocks */
ib {
    display: inline-block;
}

/* Standout color for my headings */
blue {
    color: rgba(0, 162, 255, 0.801);
}

/* Float in animation */
floatup {
    animation: animate-wrap 1s;
}

@keyframes animate-wrap {
    0%{
        opacity: 0;
        transform: translate(0, 20px);
    }
    50%{
        opacity: 0.8;
    }
    100%{
        opacity: 1;
    }
  }

/* Handling for Mobile */
@media only screen and (max-width: 600px){
    h1 {
        font-size: 5rem;
    }

    h2 {
        font-size: 3rem;
    }

    h3 {
        font-size: 2rem;
    }
    pre {
        font-size: 2rem;
    }
}

/* Wrapper for everything */
condense {
    padding: 0 8em;
    padding-top: 2rem;
}